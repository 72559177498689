<template>
    <div>
      <mail-history-list></mail-history-list>
    </div>
  </template>
  
  <script>
  export default {
  }
  </script>
  